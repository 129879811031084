<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchForm"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Sending_time')" prop="dateType">
                <!--<el-date-picker-->
                <!--v-model="searchTime"-->
                <!--:clearable="false"-->
                <!--:type="timeType"-->
                <!--range-separator="至"-->
                <!--start-placeholder="开始日期"-->
                <!--end-placeholder="结束日期"-->
                <!--value-format="yyyy-MM-dd HH:mm:ss"-->
                <!--:default-time="['00:00:00', '23:59:59']"-->
                <!--:picker-options="pickerOptions"-->
                <!--&gt;-->
                <!--</el-date-picker>-->
                <el-date-picker
                  v-model="searchTime"
                  type="datetimerange"
                  range-separator="至"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                @click="
                  page = 1;
                  searchData();
                "
              >
                查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="info" icon="el-icon-search" @click="pushData()">
                发送消息
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            prop="content"
            align="center"
            :label="$t('list.send_content')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="operator"
            align="center"
            :label="$t('list.sender')"
          >
          </el-table-column>
          <el-table-column
            prop="createdTime"
            align="center"
            :label="$t('list.Sending_time')"
          >
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// exportExcelNew
import { dateFormat, exportExcelNew } from "@/common/js/public";
import i18n from "@/i18n";
export default {
  name: "pdaPush",
  data() {
    const start = new Date(new Date().getTime() - 86400000 * 30);
    const end = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    //      let today = new Date(new Date().getTime());
    //      let yesterday = new Date(new Date().getTime() - 86400000 * 31);
    return {
      upData: {
        "Session-Id": sessionStorage.token,
      },
      dialogVisible: false,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableCols: [
        {
          prop: "dispatchType",
          label: this.$t("list.Recipient_type"),
          width: "",
          formatter: (row, column) => {
            if (row.dispatchType === 10) {
              return i18n.t("list.Full_platform");
            } else if (row.dispatchType === 11) {
              return i18n.t("list.Belonging_operator1");
            } else if (row.dispatchType === 12) {
              return i18n.t("list.PDA_account");
            } else {
              return "";
            }
          },
        },
        {
          prop: "receiverStr",
          label: this.$t("list.recipient"),
          width: "",
        },
      ],
      teamList: [],
      tableData: [],
      timeType: "daterange",
      //        searchTime: [end, start],
      searchTime: [
        dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
        dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      ],
      formInline: {
        pdaManagerName: "",
        pdaManagerCode: "",
        roleId: "",
        dateType: "1",
        startTime: "",
        endTime: "",
        operationId: "",
      },
      pickerOptions: {
        // 当天和当天以后设置为灰
        disabledDate(time) {
          return time.getTime() >= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
    };
  },
  methods: {
    // 所属运营商选择
    getOperationInfo(val) {
      this.formInline.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    pushData() {
      this.$router.push({
        path: "/pdaPushAE",
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    beforeUpload() {
      this.dialogVisible = true;
      this.picFlag = false;
      this.fileName = "";
      this.$refs.upload.clearFiles();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 时间校验
    showlog() {
      if (!this.searchTime) {
        this.$alert("搜索时间不能为空", "提示");
        return false;
      }
      let data1 = Date.parse(this.searchTime[0].replace(/-/g, "/"));
      let data2 = Date.parse(this.searchTime[1].replace(/-/g, "/"));
      let datadiff = data2 - data1;
      let time = 31 * 24 * 60 * 60 * 1000;
      if (datadiff > time) {
        this.$alert("搜索时间不能超过31天", "提示");
        return false;
      }
      if (this.formInline.startTime > this.formInline.endTime) {
        this.$alert("结束时间不能小于开始时间", "提示");
        return false;
      } else {
        return true;
      }
    },
    // 搜索
    searchData() {
      let flag = this.showlog();
      if (flag) {
        if (this.searchTime) {
          this.formInline.startTime = this.searchTime[0];
          this.formInline.endTime = this.searchTime[1];
        }
        this.loading = true;
        this.tableData = [];
        let url = "/acb/2.0/dispatch/query";
        this.$axios
          .get(url, {
            data: {
              page: this.page,
              pageSize: this.pageSize,
              startTime: this.formInline.startTime,
              endTime: this.formInline.endTime,
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.state == 0) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            } else {
              this.$alert(res.desc, this.$t("pop_up.Tips"), {
                confirmButtonText: this.$t("pop_up.Determine"),
              });
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
  },
  components: {},
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    this.formInline.startTime = dateFormat(this.searchTime[0], "yyyy-MM-dd");
    this.formInline.endTime = dateFormat(this.searchTime[1], "yyyy-MM-dd");
    this.searchData();
  },
  activated() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-icon-date-custom {
  position: relative;
  left: -26px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
<style scoped>
/* .el-button { */
/* margin: 0 !important; */
/* } */
</style>
